export default class Images {
  lazyLoad() {
    //__ Lazy load images : https://web.dev/lazy-loading-images/
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    if ("IntersectionObserver" in window) {
      let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            // lazyImage.parentNode.classList.add("in");
            lazyImage.src = lazyImage.dataset.src;
            if (lazyImage.dataset.srcset) {
              lazyImage.srcset = lazyImage.dataset.srcset;
            }
            lazyImage.classList.remove("lazy");
            lazyImageObserver.unobserve(lazyImage);
            // setTimeout(() => {
            //   lazyImage.parentNode.classList.remove("lazyloading");
            //   lazyImage.parentNode.classList.remove("in");
            // }, "1800");
          }
        });
      });
      lazyImages.forEach(function(lazyImage) {
        // lazyImage.parentNode.classList.add("lazyloading");
        lazyImageObserver.observe(lazyImage);
      });
    } else {
      lazyImages.forEach(function(lazyImage) {
        lazyImage.src = lazyImage.dataset.src;
        if (lazyImage.dataset.srcset) {
          lazyImage.srcset = lazyImage.dataset.srcset;
        }
        lazyImage.classList.remove("lazy");
      });
    }
  }
}