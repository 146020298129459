import $ from 'jquery';

export default class Search {

  init() {

    $('.search-toggler').on('click', function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      let elm = $(this).closest('.search-widget');
      if ($('df-mobile').length > 0) {
        elm.find('input[name="s"]')[0].focus();
      } else {
        if (!elm.hasClass('search-active')) {
          elm.addClass('search-active');
          elm.find('input[name="s"]')[0].focus();
        } else {
          elm.removeClass('search-active');
        }
      }
    });

    $('.search-closer').on('click', function (e) {
      e.preventDefault();
      let elm = $(this).closest('.search-widget');
      if (elm.hasClass('search-active')) {
        elm.removeClass('search-active');
      }
    });

    $('body').on('click', function (e) {
      if(!$(e.target).closest('.search-form').length) {
        let elm = $('.search-widget');
        if (elm.hasClass('search-active')) {
          elm.removeClass('search-active');
        }
      }
    });

  }

}
